.nft-card {
  border-radius: 20px;
  text-align: center;
  overflow: hidden;
  font-weight: 600;
  .nft-name {
    padding: 20px 0 0;
    font-size: 18px;
    display: block;
    @media screen and (max-width: 767px) {
      font-size: 14px;
    }
  }
  .nft-img {
    margin: 10px auto;
    display: flex;
    min-height: 200px;
    max-height: 200px;
    object-fit: contain;
    width: 90%;
    @media screen and (max-width: 767px) {
      min-height: 100px;
      max-height: 100px;
    }
  }
  .nft-token {
    @media screen and (max-width: 767px) {
      font-size: 12px;
      line-height: 1.6;
    }
  }
  .nft-util {
    padding: 15px;
    background: #f5f5f5;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .nft-price {
      display: flex;
      align-items: center;
      gap: 4px;
    }
    @media screen and (max-width: 767px) {
      .nft-price {
        font-size: 12px;
      }
      .secondary-btn {
        height: 26px;
        width: 58px;
      }
    }
  }
}
