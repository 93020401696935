.login-btn {
    height: 25px;
    width: 65px;
    font-size: 12px;
}

.login-header{
    text-align: center;
    position: relative;
    img {
        position: absolute;
        left: 50%;
        top: 0;
        transform: translate(-50%, -100%);
    }
    .sub-text{
        padding: 15px 0 0;
      display: block;
      font-size: 18px;
      font-weight: 900;
    }
  }
  
  .login-form {
    padding: 0 20px;
    text-align: center;
    .helper-text{
      margin: 35px 0 0;
      font-weight: 800;
    }
  }