$primary: #FF9E63;
.buy-detail {
  text-align: center;
  background: rgba(0, 0, 0, 0.7);
  border-radius: 20px;
  padding: 20px;
  .nft {
    margin: 0px auto 15px;
    display: flex;
    min-height: 100px;
    max-height: 120px;
  }
  .nft-name {
    font-weight: 400;
    display: block;
    color: #fff;
    margin:  0 0 10px;
  }
}
.sell-detail {
  &.form {
    margin: 20px 0 0;
    .label {
      display: block;
      text-align: left;
    }
    .primary-input {
      margin: 10px 0 20px;
    }
    .primary-btn {
      width: 100%;
      &.row-btn {
        width: 48%;
        background: gray;
        @media screen and (max-width: 768px) {
          width: 100%;
        }
        &:last-child {
          margin-left: 10px;
          background: $primary;
          @media screen and (max-width: 768px) {
            margin: 15px 0 0;
          }
        }
      }
    }
  }
}
.modal-btn {
  margin-top: 20px;
  width: 100%;
}
  