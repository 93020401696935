$primary: #ff9e63;
.profile {
  padding: 40px 0;
  .profile-info {
    text-align: center;
    margin: 0 auto 15px;
    font-weight: 600;
    position: relative;
    .log-btn {
      display: block;
      margin: 15px 0 0 auto; 
      @media screen and (max-width: 767px) {
        margin: 15px auto; 
    }
    }
  }
  .profile-img {
    margin: 0 auto 20px;
    position: relative;
    border: 4px solid #ff9e63;
    width: 150px;
    height: 150px;
    border-radius: 50%;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    .icon {
      position: absolute;
      left: 50%;
      bottom: 0;
      width: 40px;
      height: 40px;
      border-radius: 50%;
      transform: translate(-50%, 50%);
      background: rgba(0, 0, 0, 0.6);
      border: none;
      box-shadow: none;
    }
  }
  .address {
    cursor: copy;
    display: flex;
    align-items: center;
    background: #2b2d33;
    color: #fff;
    padding: 7px 12px 7px 7px;
    border-radius: 20px;
    margin: 0 auto 20px;
    width: max-content;
    svg {
      width: 24px;
      height: 26px;
      background: $primary;
      border-radius: 20px;
      padding: 4px;
      margin: 0 5px 0 0;
    }
  }
  .user-balances {
    display: flex;
    flex-wrap: wrap;
    gap: 30px;
    justify-content: center;
    margin: 0px 0 30px;
    .balance {
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex: 1;
      padding: 15px;
      border-radius: 20px;
      .label {
        display: flex;
        align-items: center;
        gap: 10px;
        img {
          width: 35px;
          height: 35px;
          border-radius: 50%;
        }
      }
      .value {
        font-weight: 700;
        font-size: 18px;
      }
    }
  }
  .nft-list {
    margin: 25px 0 0;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(258px, 1fr));
    gap: 30px;
    @media screen and (max-width: 767px) {
      grid-template-columns: repeat(auto-fill, minmax(158px, 1fr));
      gap: 7px;
  }
  }
}
