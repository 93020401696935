.modal-container {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 20;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  pointer-events: none;
  background: rgba(0, 0, 0, 0.8);
  &.isOpen {
    pointer-events: all;
    .modal {
      animation: blowUpModal 0.5s cubic-bezier(0.165, 0.84, 0.44, 1) forwards;
    }
  }
  .modal {
    max-width: 540px;
    border-radius: 18px;
    width: 100%;
    border-radius: 0.8rem;
    transform: scale(0);
    background: #fff;
    border: 2px solid #e5e7eb;
    position: relative;
    animation: blowUpModalTwo 0.5s cubic-bezier(0.165, 0.84, 0.44, 1) forwards;
    .modal__details {
      .close-btn {
        position: absolute;
        right: 20px;
        top: 15px;
        border-radius: 50%;
        width: 40px;
        height: 40px;
        font-weight: 900;
        font-size: 16px;
        line-height: 2.3;
        z-index: 2;
      }
    }
    .modal__title {
      padding: 25px 25px 0;
      font-size: 18px;
      text-align: center;
      font-weight: 800;
    }
    .modal__description {
      padding: 25px;
      font-size: inherit;
      color: inherit;
      font-family: inherit;
    }
  }
}

@keyframes blowUpContent {
  0% {
    transform: scale(0);
    opacity: 1;
  }
  99.9% {
    transform: scale(2);
    opacity: 0;
  }
  100% {
    transform: scale(1);
  }
}

@keyframes blowUpContentTwo {
  0% {
    transform: scale(2);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}

@keyframes blowUpModal {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes blowUpModalTwo {
  0% {
    transform: scale(1);
    opacity: 1;
  }
  100% {
    transform: scale(0);
    opacity: 0;
  }
}
