.header {
    display: flex;
    align-items: center;
    padding: 20px 5%;
    justify-content: space-between;
  box-shadow: 0 0 #0000, 0 0 #0000, 0 4px 6px 0px rgba(0,0,0,.04);
  .logo{
    display: block;
    max-width: 40px;
    img {
        width: 100%;
    }
  }
  .profile-btn{
    border: 2px solid rgba(0,0,0,.04);
    width: 50px;
    height: 50px;
    border-radius: 50%;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

}