* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  font-family: 'Montserrat', sans-serif;
}

$primary: #FF9E63;

button, input, textarea {
  outline: none;
  font-size: 16px;
} 

a {
  text-decoration: none;
  color: #000;
}

.orange-label {
  color: #ffac00; 
}

input::-moz-placeholder,textarea::-moz-placeholder {
  color: #9ca3af
}

input::placeholder,textarea::placeholder {
  color: #9ca3af
}



.shadow {
  box-shadow: 0 0 #0000, 0 0 #0000, 2px 2px 0 0 #3a3831;
  border: 2px solid #3a3831;
}

button {
  @extend .shadow;
  background: $primary;
  border-radius: 25px;
  cursor: pointer;
  font-weight: 600;
  text-transform: capitalize
}


.primary-input {
  @extend .shadow;
  border-radius: 10px;
  width: 100%;
  padding: 14px 10px;
  margin: 0 0 35px;
}

.primary-btn {
  height: 45px;
  width: 182px;
  line-height: 24px;
  .svg {
    margin: 0 15px 0 0;
  }
}

.secondary-btn {
  height: 30px;
  width: 100px;
  font-size: 12px;
}

.container {
  max-width: 1280px;
  margin: 0 auto;
  padding: 0 25px;
}





.nft-token {
  margin: 0 0 15px;
  background: rgba(0, 0, 0, 0.3);
  border-radius: 20px;
  display: inline-flex;
  align-items: center;
  text-align: center;
  padding: 4px 9px 4px 6px;
  font-size: 16px;
  font-weight: 600;
  color: #fff;
  .tag {
      background: #000;
      border-radius: 50%;
      width: 20px;
      height: 20px;
      margin: 0 6px 0 0;
  }
}


.error{
  font-weight: 500;
  color: red;
}




::-webkit-scrollbar-track
{
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
	border-radius: 10px;
	background-color: #F5F5F5;
}

::-webkit-scrollbar
{
	width: 10px;
	height: 8px;
	background-color: #F5F5F5;
}

::-webkit-scrollbar-thumb
{
	border-radius: 10px;
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
	background-color:$primary;
}


.icon-list{
  position: fixed;
  max-width: 350px;
  min-width: 300px;
  bottom: 0;
  background: gray;
  padding: 10px;
  left: 50%;
  transform: translate(-50%, 0);
  border-radius: 8px 8px 0 0;
  margin: 0 0 0 auto;
  list-style: none;
  display: flex;
  align-items: center;
  align-content: center;
  gap: 15px;
  li {
    a {
      display: block;
      max-width: 130px;
      svg {
        max-width: 100px;
        width: 100%;
      }
      img{
        width: 100%;
      }
    }
  }
}

.Toastify__toast.Toastify__toast-theme--light {
  @extend .shadow
}

