$primary: #FF9E63;
.listed-nft {
    padding: 35px 0 75px;
    .nft-list {
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(258px, 1fr));
        gap: 30px;
        @media screen and (max-width: 767px) {
            grid-template-columns: repeat(auto-fill, minmax(158px, 1fr));
            gap: 7px;
    }
    }
}

.chips {
    padding: 25px 0 5px;
    overflow: auto;
    display: flex;
    .chip{
        background: #fff;
        width: max-content;
        white-space: nowrap;
        font-size: 13px;
        padding: 3px 12px;
        margin: 0 10px 0 0;
       &.active {
        background: $primary;
       }
    }
  }