$primary: #FF9E63;
.buy-nft {
  padding: 40px 0 0;
  .buy-now {
    display: flex;
    align-items: center;
    margin: 40px 0 0;
    gap: 25px;
    @media screen and (max-width: 768px) {
      display: block;
    }
    .nft-img {
      text-align: center;
      flex: 1;
      img {
        min-height: 300px;
        max-height: 400px;
        max-width: 400px;
        object-fit: contain;
        width: 100%;
        height: 100%;
      }
    }
    .nft-detail {
      flex: 1;
      .disc {
        margin: 10px 0 0;
      }
      .primary-btn {
        margin: 30px 0;
        @media screen and (max-width: 768px) {
          margin: 15px 0;
          width: 100%;
        }
      }
      .nft-properties {
        padding: 25px;
        border-radius: 20px;
        .row {
          margin: 0 0 20px;
          display: flex;
          justify-content: space-between;
          .label {
            color: #939599;
            font-size: 14px;
            font-weight: 500;
          }
          .value {
            font-size: 14px;
            font-weight: 700;
          }
        }
      }
    }
  }
}


