.notification {
    display: flex;
    justify-content: left;
    padding: 0;
    font-family: inherit;
    font-family: 'Nunito Sans', sans-serif;
    .icon {
        height: 30px;
        width: 30px;
        min-width: 30px;
        margin-bottom: 0px;
    }
    .message {
        color: rgba(0,0,0, 0.7);
        font-size: 14px;
        line-height: 1.4;
        margin: 0px 0px 0px 10px;
        cursor: pointer;
        word-break: normal;
        hyphens: auto;
        font-weight: 600;
    }
}

.Toastify .Toastify__close-button--light {
    box-shadow: none;
    opacity: 1;
}